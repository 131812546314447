<template>
  <div class="animated">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="6" sm="8">
          <div id="snackbar"></div>
          <b-card no-body class="mx-1">
            <b-card-body class="p-4">
              <b-form v-on:submit.prevent.self="submit">
                <h1>Register</h1>
                <p class="text-muted">Create your account</p>
                <b-input-group class="mb-3">
                  <b-input-group-prepend>
                    <b-input-group-text>
                      <i class="icon-user"></i>
                    </b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input
                    type="text"
                    required
                    v-model="user.UserName"
                    class="form-control"
                    placeholder="Username"
                    autocomplete="username"
                  />
                </b-input-group>

                <b-input-group class="mb-3">
                  <b-input-group-prepend>
                    <b-input-group-text>
                      <i class="fa fa-address-book-o"></i>
                    </b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input
                    v-on:keyup.native="onKeyHandler('phoneNumber')"
                    type="number"
                    step="any"
                    required
                    v-model="user.Mobile"
                    class="form-control"
                    placeholder="Mobile"
                    autocomplete="mobile"
                  />
                </b-input-group>

                <b-input-group class="mb-3">
                  <b-input-group-prepend>
                    <b-input-group-text>
                      <i class="fa fa-user-o"></i>
                    </b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-select
                    v-model="user.RoleID"
                    id="role"
                    @change.native="onChangeRole($event.target.value)"
                    :plain="true"
                    required
                    value-field="RoleID"
                    text-field="Role"
                    :options="roles"
                  >
                    <template slot="first">
                      <option :value="0" disabled>-- Pick a role --</option>
                    </template>
                  </b-form-select>
                </b-input-group>

                <b-input-group
                  v-if="userObject.status.organization"
                  class="mb-3"
                >
                  <b-input-group-prepend>
                    <b-input-group-text>
                      <i class="fa fa-user-o"></i>
                    </b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-select
                    id="Cinema"
                    :plain="true"
                    required
                    value-field="OrganizationID"
                    text-field="OrganizationName"
                    v-model="organization.selectOrganization"
                    :options="organization.organizationList"
                  >
                    <template slot="first">
                      <option :value="'0'" disabled
                        >-- Select Organization --</option
                      >
                    </template>
                  </b-form-select>
                </b-input-group>

                <b-input-group v-if="userObject.status.cinema" class="mb-3">
                  <b-input-group-prepend>
                    <b-input-group-text>
                      <i class="fa fa-user-o"></i>
                    </b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-select
                    id="Cinema"
                    :plain="true"
                    required
                    value-field="CinemaID"
                    text-field="CinemaName"
                    v-model="userObject.selectedCinema"
                    :options="userObject.cinemaList"
                  >
                    <template slot="first">
                      <option :value="'0'" disabled>-- Select Cinema --</option>
                    </template>
                  </b-form-select>
                </b-input-group>
                <b-input-group
                  v-if="userObject.status.distributor"
                  class="mb-3"
                >
                  <b-input-group-prepend>
                    <b-input-group-text>
                      <i class="fa fa-user-o"></i>
                    </b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-select
                    v-model="userObject.selectedDistributor"
                    id="role"
                    :plain="true"
                    required
                    value-field="DistributorID"
                    text-field="DistributorName"
                    :options="userObject.distributorList"
                  >
                    <template slot="first">
                      <option :value="'0'" disabled
                        >-- Select Distributor --</option
                      >
                    </template>
                  </b-form-select>
                </b-input-group>

                <b-input-group class="mb-3">
                  <b-input-group-prepend>
                    <b-input-group-text>@</b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input
                    type="text"
                    required
                    v-model="user.Email"
                    class="form-control"
                    placeholder="Email"
                    autocomplete="email"
                  />
                </b-input-group>

                <b-input-group class="mb-3">
                  <b-input-group-prepend>
                    <b-input-group-text>
                      <i class="icon-lock"></i>
                    </b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input
                    type="password"
                    required
                    v-model="user.Password"
                    class="form-control"
                    placeholder="Password"
                    autocomplete="new-password"
                  />
                </b-input-group>

                <b-input-group class="mb-4">
                  <b-input-group-prepend>
                    <b-input-group-text>
                      <i class="icon-lock"></i>
                    </b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input
                    type="password"
                    required
                    v-model="confirmPassword"
                    class="form-control"
                    placeholder="Repeat password"
                    autocomplete="new-password"
                  />
                </b-input-group>

                <b-input-group class="mb-4">
                  <b-input-group-prepend>
                    <b-input-group-text>
                      <i class="fa fa-flag"></i>
                    </b-input-group-text>
                  </b-input-group-prepend>
                  <label class="text-muted mx-2 my-2">Status</label>
                  <c-switch
                    class="mx-5 my-2"
                    color="success"
                    v-model="user.IsActive"
                    defaultChecked
                    variant="3d"
                    label
                    v-bind="labelIcon"
                  />
                  <!-- <b-form-input type="password" required v-model="user.IsActive" class="form-control" placeholder="Repeat password" autocomplete="new-password" /> -->
                </b-input-group>

                <b-button type="submit" variant="success" block>{{
                  text.userButton
                }}</b-button>
                <b-button @click="resetForm" variant="danger" block
                  >Reset</b-button
                >
                <b-button variant="danger" v-if="checkQuery" block
                  >Reset Password</b-button
                >
              </b-form>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </div>

    <b-modal
      id="ModalConfirmationOnSubmit"
      ref="ModalConfirmationOnSubmit"
      @ok="handleModalOkay"
      :title="modalObject.title"
      class="center-footer"
      footer-class="justify-content-center border-0"
      cancel-variant="outline-danger"
      ok-variant="outline-success"
      centered
      hide-header
      no-close-on-esc
      no-close-on-backdrop
    >
      <div class="d-block text-center">
        <div>{{ modalObject.message }}</div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { Switch as cSwitch } from "@coreui/vue";
import UserService from "@/services/UserService";
import CinemaService from "@/services/CinemaService";
import MovieService from "@/services/MovieService";

export default {
  name: "AddUser",
  components: {
    cSwitch,
  },
  data() {
    return {
      checkQuery: false,

      roleName: "",

      text: {
        userButton: "Add User",
      },
      organization: {
        organizationList: [],
        selectOrganization: "0",
      },
      userObject: {
        selectedDistributor: "0",
        selectedCinema: "0",
        cinemaList: [],
        distributorList: [],
        status: {
          distributor: false,
          cinema: false,
          organization: false,
        },
      },
      user: {
        ID: null,
        UserName: "",
        Password: "",
        Email: "",
        Flag: 1,
        Mobile: "",
        LoginFrom: "Dashboard",
        RoleID: 0,
        IsActive: true,
      },
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715",
      },
      confirmPassword: "",
      roles: [],
    };
  },

  mounted() {
    this.checkRouteQuery();
    this.getRoles();

    const { UserID } = this.$route.query;
    if (UserID) {
      this.getUserDetailsById(UserID);
    }
  },
  methods: {
    checkRouteQuery() {
      this.$route.query.UserID
        ? (this.checkQuery = true)
        : (this.checkQuery = false);
    },
    getUserDetailsById: function (UserID) {
      this.text.userButton = "Update User";
      // let res = await UserService.getUserByID(userID);
      let payload = {
        UserID,
      };
      UserService.getUserByID(payload)
        .then((response) => {
          const { data } = response;
          if (data.Status && data.UserList.length > 0) {
            this.user = data.UserList[0];
            this.user["LoginFrom"] = "Dashboard";
            this.user.Flag = 2;
            this.confirmPassword = data.UserList[0].Password;
            this.onChangeRole(data.UserList[0].RoleID);
            if (data.UserList[0].Role == "Distributor") {
              this.userObject.selectedDistributor =
                data.UserList[0].DistributorID;
            } else if (data.UserList[0].Role == "Organization") {
              this.organization.selectOrganization =
                data.UserList[0].OrganizationID;
            } else if (data.UserList[0].Role == "Cinema") {
              this.userObject.selectedCinema = data.UserList[0].CinemaID;
            }
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch((error) => {
          console.log("Catch on AddUser-UserDetails Error: ", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },

    onChangeRole: function (value) {
      this.userObject.status.cinema = false;
      this.userObject.status.distributor = false;
      this.userObject.status.organization = false;
      this.userObject.selectedDistributor = "0";
      this.userObject.selectedCinema = "0";
      this.organization.selectOrganization = "0";

      let array = this.roles.filter((x) => {
        return x.RoleID == value;
      });
      if (array.length > 0) {
        this.roleName = array[0].Role;

        if (array[0].Role == "Cinema") {
          this.userObject.status.cinema = true;
          this.getCinemasActiveList(0);
        } else if (array[0].Role == "Distributor") {
          this.userObject.status.distributor = true;
          this.getDistributorList();
        } else if (array[0].Role == "Organization") {
          this.userObject.status.organization = true;
          this.getOrganization();
        }
      }
    },
    getOrganization: function () {
      this.organization.organizationList = [];
      CinemaService.getOrganization()
        .then((response) => {
          const { data } = response;
          if (data.Status && data.OrganizationList.length > 0) {
            this.organization.organizationList = data.OrganizationList;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch((error) => {
          console.log("Catch on AddUser-getDistributorList Error: ", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },

    getDistributorList: function () {
      this.userObject.distributorList = [];
      CinemaService.getDistributorList()
        .then((response) => {
          const { data } = response;
          if (data.Status && data.DistributorList.length > 0) {
            this.userObject.distributorList = data.DistributorList;
          } else {
            this.showMessageSnackbar(
              data.Message || "There is not data for distributors"
            );
          }
        })
        .catch((error) => {
          console.log("Catch on AddUser-getDistributorList Error: ", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },

    getCinemasActiveList: function (OrgID) {
      this.cinemas = [];
      let payload = {
        OrgID,
      };
      CinemaService.getActiveCinemas(payload)
        .then((response) => {
          const { data } = response;
          if (data.Status && data.CinemaList.length > 0) {
            this.userObject.cinemaList = data.CinemaList;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch((error) => {
          console.log("Catch on AddUser-getCinemasDDL Error: ", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    getRoles: function () {
      this.roles = [];
      UserService.getUserRoles()
        .then((response) => {
          const { data } = response;
          if (data.Status && data.Userrole.length > 0) {
            this.roles = data.Userrole;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch((error) => {
          console.log("Catch on AddUser-getRoles Error: ", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    onKeyHandler: function (name) {
      if (name == "phoneNumber") {
        this.user.Mobile = this.user.Mobile.slice(0, 10);
      }
    },
    validation: function () {
      let userRege = /^[a-zA-Z0-9]{4,12}$/;
      let phoneReg = /\d{7}/;
      let emailReg =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
      let passwordRege = /(?=.{4,})/;
      let value = false;
      if (!userRege.test(this.user.UserName)) {
        this.showMessageSnackbar("User Name must be 4-12 characters long.");
        return false;
      } else if (!phoneReg.test(this.user.Mobile)) {
        this.showMessageSnackbar("Phone number must be at least 10 digits.");
        return false;
      } else if (this.user.RoleID == 0) {
        this.showMessageSnackbar("Please pick a user Role");
        return false;
      } else if (
        this.userObject.status.cinema &&
        this.userObject.selectedCinema == "0"
      ) {
        this.showMessageSnackbar("Please select the Cinema");
        return false;
      } else if (
        this.userObject.status.distributor &&
        this.userObject.selectedDistributor == "0"
      ) {
        this.showMessageSnackbar("Please select the Distributor");
        return false;
      } else if (
        this.userObject.status.organization &&
        this.organization.selectOrganization == "0"
      ) {
        this.showMessageSnackbar("Please select the Organization");
        return false;
      } else if (!emailReg.test(this.user.Email)) {
        this.showMessageSnackbar("Email address must be unique and valid.");
        return false;
      } else if (!passwordRege.test(this.user.Password)) {
        this.showMessageSnackbar("Password must be minimum 4 characters.");
        return false;
      } else if (this.user.Password !== this.confirmPassword) {
        this.showMessageSnackbar(
          "Passwords don't match. Please confirm password."
        );
        return false;
      } else {
        return true;
      }
    },
    submit: function () {
      if (this.validation()) {
        let payload = {
          title: "Confirmation",
          message: "Please re-check the data before submit",
        };
        this.showSubmitConfirmation(payload);
      }
    },
    submitFinalData: function () {
      this.closeModal("ModalConfirmationOnSubmit");

      let finalObject = Object.assign({}, this.user);
      finalObject["DistributorID"] =
        this.userObject.selectedDistributor == "0"
          ? 0
          : this.userObject.selectedDistributor;
      finalObject["CinemaID"] =
        this.userObject.selectedCinema == "0"
          ? 0
          : this.userObject.selectedCinema;
      finalObject["OrganizationID"] =
        this.organization.selectOrganization == "0"
          ? 0
          : this.organization.selectOrganization;
      UserService.addUser(finalObject)
        .then((response) => {
          const { data } = response;
          this.showMessageSnackbar(data.Message);

          if (data && data.Status) {
            this.resetForm();
            if (this.roleName == "User") {
              this.$router.push({
                path: "/user-management/app-users",
              });
            } else {
              this.$router.push({
                path: "/user-management/dash-users",
              });
            }
          }
        })
        .catch((error) => {
          console.log("Catch on AddUser Error: ", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    resetForm: function () {
      this.user = {
        ID: null,
        UserName: "",
        Password: "",
        Email: "",
        Flag: 1,
        Mobile: "",
        LoginFrom: "Dashboard",
        RoleID: 0,
        IsActive: true,
      };
      this.userObject.selectedCinema = "0";
      this.userObject.selectedDistributor = "0";
      this.organization.selectOrganization = "0";
      this.confirmPassword = "";
      this.text.userButton = "Add User";

      window.location.replace(
        window.location.href.split("?")[0]
        // window.location.origin + "/" + window.location.hash.split("?")[0]
      );
    },
    // Show Confirmmation Dialog
    handleModalOkay: function (evt) {
      // evt.preventDefault();
      this.modalObject.count = 1;
      this.submitFinalData();
    },
    showSubmitConfirmation: function (payload) {
      this.modalObject.title = payload.title; // "Confirmation";
      this.modalObject.message = payload.message; // "Please re-check the data before submit";
      this.openModal("ModalConfirmationOnSubmit");
    },
    openModal: function (ref) {
      this.$refs[ref].show();
    },
    closeModal: function (ref) {
      this.$refs[ref].hide();
      this.modalObject.title = ""; // "Confirmation";
      this.modalObject.message = ""; // "Please re-check the data before submit";
    },
    // Show Confirmmation Dialog End
    // Show Error Message
    showMessageSnackbar: function (message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function () {
        x.className = x.className.replace("show", "");
      }, 3000);
    },

    // value-field="OrganizationID"
    // text-field="OrganizationName"

    //                  <template slot="first">
    //                     <option :value="'0'" disabled>-- Select Organization --</option>
    //                 </template>

    // <b-modal id="ModalConfirmationOnSubmit" ref="ModalConfirmationOnSubmit" @ok="handleModalOkay" :title="modalObject.title" class="center-footer" footer-class="justify-content-center border-0" cancel-variant="outline-danger" ok-variant="outline-success" centered hide-header no-close-on-esc no-close-on-backdrop >
    //     <div class="d-block text-center">
    //       <div>{{ modalObject.message }}</div>
    //     </div>
    //   </b-modal>

    //  validation: function() {
    //   return true;
    // },

    //   if (this.validation()) {
    //     let payload = {
    //       title: "Confirmation",
    //       message: "Please re-check the data before submit"
    //     };
    //     this.showSubmitConfirmation(payload);
    //   }

    // .then(response => {
    //       const { data } = response;
    //       if (data.Status && data.Userrole.length > 0) {
    //       } else {
    //         this.showMessageSnackbar(data.Message);
    //       }
    //     })
    //     .catch(error => {
    //       this.showMessageSnackbar(
    //         "Oops! Something went wrong. kindly contact your administrator"
    //       );
    //     });
  },
};
</script>
<style>
.form-control {
  border-radius: 0px;
}
</style>
